export const Mail_Vars = {
    fb_link: "https://www.facebook.com/",
    tw_link: "https://twitter.com/",
    sitename: "C & N Estate Agents",
    contact_email: "clapham@cnestateagents.com",
    company_phone: "0123 485 4050",
    available_time: "9am - 5pm ,",
    available_days: " Monday - Friday",
    address: "6 Clapham High Street, Clapham, London SW4 7UT",
    primary_color: "#BFBBAC"
}


export const Site_Vars = {
    default_currency: "£"
}